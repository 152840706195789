.qadpt-grdicon {
	width: 30px !important;
	padding: 4px !important;
}
.qadpt-menupopup {
	z-index: 999;
	padding: 10px;
	min-width: 170px;
	border-radius: 8px;
	position: absolute;
	background: var(--white-color);
    box-shadow: -5px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	right: 20px;
	button {
		background-color: transparent !important;
	}
	.qadpt-actionpopup {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #000000;
		span {
			margin-left: 10px;
		}
	}
}
// .qadpt-iconspositions {
// 	margin-right: 8;
// 	font-size: 18;
// }

.qadpt-loaderstyles {
	transform: translate(-50%, -50%);
	z-index: 1;
	position: absolute;
	top: 225px;
	left: 475px;
	width: 100%;
	height: 77%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
}
.qadpt-loaderSpinnerStyles {
	width: 45px;
	height: 45px;
}
.qadpt-deletepopupwidth {
	width: 200px;
}

.qadpt-memberButton {
	background-color: var(--button-bg-color);
	border: none;
	border-radius: 15px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 10px;
	height: 40px;

	i {
		margin-right: 10px;
		font-size: 14px !important;
	}

	// Styling for the Save button when updated
	&.qadpt-mem-updated {
		background-color: var(--button-bg-color); // The same background color as the member button
	}

	// Disabled state styling
	&.qadpt-disabled {
		background-color: #ccc; // Disabled background color
		cursor: not-allowed; // Show as disabled
	}
}

.qadpt-membersave {
	position: absolute;
	cursor: pointer;
	font-size: 14px;
	background: transparent;
	color: var(--white-color);
	border-radius: 15px;
	// padding: 12px 31px;
	background-color: #91a7d9;
	border: 0px solid #91a7d9;
	&.isLabelUpdated {
		background-color: var(--button-bg-color);
	}
}
.qadpt-deletepopupCancelbutton {
	position: relative;
	background: var(--white-color);
	color: #007bff;
	border: 1px solid #007bff;
	margin-right: 27px;
	right: 0px;
	top: 10px;
}
.qadpt-deletepopupButton {
	position: relative;
	right: 0px;
	top: 10px;
}
.qadpt-usereditcancelButton {
	position: absolute;
	right: 220px;
	z-index: 99;
	top: 67px;
	background-color: #007bff;
	border: none;
	border-radius: 5px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
	top: 440px;
}
.qadpt-usereditbuttonsdiv {
	margin-left: 40px;
	display: flex;
	gap: 20px;
}
.qadpt-usereditsaveButton {
	position: absolute;
	right: 37px;
	z-index: 99;
	background-color: #a5c3c5;
	border: none;
	border-radius: 25px;
	color: var(--white-color);
	cursor: pointer;
	font-size: 14px;
	padding: 7px 21px;
	top: 460px;
	width: 110px;
	height: 44px;
	padding: 10px 12px 10px 12px;
}

.qadpt-userpasswordpopup {
	/* overflow-y: scroll;
	 z-index: 99999; */
	z-index: 100;
	width: 350px !important;
	height: 37vh;
	top: 200px;
	right: 400px;
	padding: 0 20px;
	position: fixed !important;
	display: block !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	border-radius: 8px;
}

.qadpt-usrhdrpossideoff {
	top: -38px;
	position: relative;
	left: -440px;
}
.qadpt-usrhdrpossideon {
	top: 2px;
	position: relative;
	right: 560px;
}
.qadpt-userCreateButtonPosition {
	top: -130px;
	position: relative;
}

.qadpt-custom-data-grid {
	border-color: var(--grid-border-color);

	.MuiDataGrid-columnHeaders {
		background: var(--grid-head-background);
	}

	.MuiDataGrid-columnHeaderTitle {
		font-weight: 500;
		color: #000;
	}

	.MuiDataGrid-cell {
		border-right: 1px solid var(--grid-border-color);
	}

	.MuiDataGrid-columnHeader,
	.MuiDataGrid-cell {
		border-right: 1px solid var(--grid-border-color);
	}

	.MuiDataGrid-row {
		.MuiDataGrid-cell {
			border-bottom: none;
		}
	}

	position: relative;
}

.st-ina {
	border: 1px solid #9a9a9a;
	color: #9a9a9a;
	background: rgba(154, 154, 154, 0.2);
	border-radius: 20px;
	height: 32px;
	margin: 10px 5px;
	display: flex;
	align-items: center;
	place-content: center;
}
.st-blo {
	border: 1px solid #e77a7a;
	color: #f57676;
	background: rgba(250, 152, 152, 0.2);
	border-radius: 20px;
	height: 32px;
	margin: 10px 5px;
	display: flex;
	align-items: center;
	place-content: center;
}
.st-pen {
	border: 1px solid #fc8d33;
	color: #f77722;
	background: rgba(250, 169, 19, 0.2);
	border-radius: 20px;
	height: 32px;
	margin: 10px 5px;
	display: flex;
	align-items: center;
	place-content: center;
}
.st-act {
	border: 1px solid var(--button-bg-color);
	color: var(--button-bg-color);
	background: #dae7e7;
	border-radius: 20px;
	height: 32px;
	margin: 10px 5px;
	display: flex;
	align-items: center;
	place-content: center;
}
.MuiDataGrid-columnHeader {
	background: var(--grid-head-background);
	color: black;
	border-right: 1px solid var(--grid-border-color);
	height: 40px !important;
}
.qadpt-setting-grd,
.qadpt-account-grd,
.qadpt-audit-grd {
	border-color: var(--grid-border-color) !important;
	.MuiDataGrid-main {
		--DataGrid-topContainerHeight: 40px !important;
	}
	.MuiDataGrid-virtualScroller {
		height: calc(100vh - 295px) !important;
	}
	.MuiDataGrid-footerContainer .MuiTablePagination-root .MuiInputBase-input {
		padding: 20px !important;
	}
	.MuiDataGrid-columnHeader {
		.MuiDataGrid-sortIcon {
			opacity: 1 !important;
			visibility: visible;
		}
	}
	.MuiDataGrid-row .MuiDataGrid-cell i {
		font-weight: 600;
	}
	.MuiDataGrid-columnHeaderTitle {
		font-weight: 600;
	}

	.MuiDataGrid-columnHeader {
		background: var(--grid-head-background);
		color: black;
		border-right: 1px solid var(--grid-border-color);
		height: 40px !important;
	}

	.MuiDataGrid-cell {
		border-right: 1px solid var(--grid-border-color);
		// height: 40px !important;
	}

	.MuiDataGrid-row {
		.MuiDataGrid-cell {
			border-bottom: none;
		}
	}
}
// .qadpt-account-grd {
// 	.MuiDataGrid-virtualScroller {
// 		height: calc(100vh - 320px) !important;
// 	}
// }
.qadpt-setting-grd {
	// .MuiDataGrid-virtualScroller {
	// 	height: calc(100vh - 280px) !important;
	// }
	.MuiDataGrid-columnHeader:nth-child(2),
	.MuiDataGrid-cell:nth-child(2),
	.MuiDataGrid-columnHeader:nth-child(3),
	.MuiDataGrid-cell:nth-child(3) {
		width: 25% !important;
		min-width: 25% !important;
		max-width: 25% !important;
	}

	.MuiDataGrid-columnHeader:nth-child(5),
	.MuiDataGrid-cell:nth-child(5) {
		width: 14% !important;
		min-width: 14% !important;
		max-width: 14% !important;
	}
	.MuiDataGrid-columnHeader:nth-child(6),
	.MuiDataGrid-cell:nth-child(6),
	.MuiDataGrid-columnHeader:nth-child(4),
	.MuiDataGrid-cell:nth-child(4) {
		width: 20% !important;
		min-width: 20% !important;
		max-width: 20% !important;
	}
}
.qadpt-midpart {
	.qadpt-content-block {
		background: var(--white-color);
		padding: 0 20px;
		border-radius: 6px;
		min-height: calc(100vh - 80px);
		.qadpt-head {
			border-bottom: 1px solid var(--border-color);
			margin: 0 -20px;
			padding: 20px;
			display: flex;
			align-items: center;
			.qadpt-title-sec {
				width: calc(100% - 200px);
				.qadpt-title {
					font-weight: 600;
					font-size: 22px;
					line-height: 20px;
				}
				.qadpt-description {
					font-size: 14px;
					line-height: 18px;
					padding: 5px 0;
				}
			}
			.qadpt-right-part {
				text-align: right;
				width: 200px;
			}
		}

		.qadpt-content-box {
			padding-bottom: 20px;
			margin: 20px 0 10px;
			.qadpt-src-flt {
				display: flex;
				place-content: end;
				align-items: center;
				.qadpt-teamsearch {
					width: 210px;
					right: 10px;
					top: -14px;
					.MuiFormHelperText-root {
						color: inherit;
					}

					.MuiOutlinedInput-root {
						padding-top: 6px;
						border-radius: 12px;
						height: 40px;

						&.Mui-focused .MuiOutlinedInput-notchedOutline {
							border: 1px solid #ccc; /* Set your preferred border color here */
						}
					}

					.MuiOutlinedInput-notchedOutline {
						height: 43px;
					}
					.MuiInputBase-input {
						height: 1em;
						padding-left: 0px !important;
					}
					button {
						padding: 0px !important;
					}
				}

				.qadpt-input-field-error {
					.MuiFormHelperText-root {
						color: var(--error-color);
					}
				}
			}
			.qadpt-usrfilter button {
				position: relative;
				padding: 0 8px;
				border: 1px solid var(--border-color);
				border-radius: 10px;
				top: -10px;
				height: 40px;
			}
			.qadpt-grd-head {
				position: relative;
				float: right;
				display: flex;
				width: 100%;
				place-content: flex-end;
				margin-bottom: 10px;
				.slt-acc-drp {
					width: 210px;
					margin: -7px 10px;
					padding: 0 !important;
					&.MuiBox-root {
						padding: 0 !important;
					}
					.MuiOutlinedInput-input {
						// padding: 5px 15px !important;
						width: 210px;
					}
					.MuiOutlinedInput-notchedOutline {
						border-radius: 10px !important;
						height: 42px !important;
					}
					.MuiInputLabel-outlined {
						top: -9px !important;
						padding: 6px;
					}
					.MuiAutocomplete-inputRoot {
						height: 42px !important;
					}
					.MuiSelect-icon {
						top: 10px;
					}
					.MuiInputLabel-outlined.MuiInputLabel-shrink {
						top: 2px !important;
					}
				}
				.qadpt-usrfilter button {
					top: -4px !important;
				}
			}
		}
	}
}

.qadpt-userButton {
	position: relative;
	right: 0;
	top: 10px;
}

/* create userpop start */
.qadpt-usercreatepopup,
.qadpt-userEditpopup {
	border: 0 !important;
	margin: 0 !important;
	width: 400px !important;
	max-height: 580px;
	min-height: 400px;
	top: 70px;
	right: 460px;
	position: fixed !important;
	display: block !important;
	background: var(--white-color) !important;
	box-shadow: 0 3px 8px #000000;
	border-radius: 4px;
	.qadpt-title-sec {
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		.qadpt-title {
			font-weight: 600;
			font-size: 18px;
			line-height: 20px;
		}
		.qadpt-description {
			font-size: 12px;
			line-height: 18px;
		}
	}
	.qadpt-formcontent {
		width: 100% !important;
		max-height: calc(100vh - 200px);
		overflow: hidden auto;
		font-size: 14px;
		height: 353px;
		.qadpt-usrname {
			display: flex;
			> div {
				width: 50%;
			}
			.qadpt-userfields {
				.MuiFormHelperText-root {
					width: 150px;
				}
			}
			div:nth-child(2) {
				margin-left: 10px;
			}
		}
		.MuiGrid-root {
			padding-top: 4px;
		}
	}
	.qadpt-userfields {
		width: 100%;
		margin-bottom: -2px;

		.MuiFormHelperText-root {
			&.error {
				color: var(--error-color) !important;
			}
		}
		.MuiSvgIcon-root {
			height: 16px;
			width: 16px;
		}
	}
	.qadpt-genderbuttons {
		height: 40px;
		gap: 7px;
		margin: 0 -7px;
		.MuiButton-root {
			min-width: 100px;
			color: var(--button-bg-color);
			border: 1px solid var(--button-bg-color) !important;
			font-size: 12px;
			white-space: nowrap;
			border-radius: 14px;
			text-transform: capitalize;
			&:hover {
				background-color: var(--button-bg-color);
				color: var(--white-color);
			}
		}

		.selected {
			background-color: var(--button-bg-color);
			color: var(--white-color);
		}
	}
	.qadpt-submitbutton {
		padding: 15px;
		width: 100%;
		text-align: end;
		border-top: 1px solid var(--bordaer-color);
		button {
			color: var(--white-color);
			border-radius: 4px;
			border: 1px solid var(--button-bg-color);
			background-color: var(--button-bg-color);
			text-transform: capitalize;
			padding: var(--button-padding) !important;
			line-height: var(--button-lineheight) !important;
			// &:hover {
			// 	color: white;
			// 	background-color: var(--button-bg-color);
			// }
		}
	}
}
/*create userpop ends */
